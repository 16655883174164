<template>
  <div class="wrapper">
    <div class="company-select">
      <el-cascader
        v-model="selectValue"
        :options="options"
        :props="optionsPros"
      />
      <el-button @click="submit">查询</el-button>
       <el-button @click="exportData">导出</el-button>
    </div>
    <div class="base" v-for="(item, index) in titleList" :key="index">
      <div class="title">
        <span>{{ item.name }}</span>
      </div>
      <div class="content" v-if="boxListInit">
        <div class="box">
          <circular-box
            v-for="(it, i) in boxList"
            :key="i"
            :item="{...it, content: boxListRender[item.key][it.field]}"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircularBox from '@/components/CircularBox'
import { getAllChildrenList, sentryStatistics, exportSentry } from '@/api'
import { exportFun } from "@/utils/params";

export default {
  name: 'dataStatisical',
  async created () {
    const childList = await getAllChildrenList({ type: 1 })
    const selectList = childList.data.data
    this.options = this.fieldData(selectList.company)
    this.renderForm({
      'group_id': localStorage.getItem('group_id')
    })
  },
  watch: {
    boxListRender () {
      this.boxListInit = true
    },
  },
  methods: {
    exportData () {
      const param = {
        type:1,
        company_id: this.selectValue[0] || '',
        department_id: this.selectValue[1] || '',
        sentry_id: this.selectValue[2] || '',
        group_id: localStorage.getItem("group_id"),
      }
      exportFun(exportSentry,param,"exportSentry")
    },
    fieldData (data) {
      let resultData = data.map(item => {
        if (item.department && item.department.length > 0) {
          item.children = item.department
        }
        return item
      })
      resultData.unshift({
        name: '全部',
        value: 'group_id'
      })
      return resultData
    },
    async renderForm (data) {
      try {
        const res = await sentryStatistics(data)
        this.boxListRender = res.data.data
      } catch (e) {
      }
    },
    submit () {
      const requestData = {
        group_id: 1,
        company_id: this.selectValue[0] || '',
        department_id: this.selectValue[1] || '',
      }
      this.renderForm(requestData)
    },
  },
  data () {
    return {
      boxListInit: false,
      optionsPros: {
        value: 'id',
        label: 'name',
        children: 'children',
        checkStrictly: true
      },
      disable: true,
      sentryList: [],
      selectValue: [],
      titleList: [
        {
          name: '',
          key: 'sentry'
        },
        {
          name: '参与总人数',
          key: 'user'
        },
        {
          name: '党员人数',
          key: 'user_party'
        },
        {
          name: '农民工数',
          key: 'user_peasant'
        },
        {
          name: '入党积极分子统计',
          key: 'user_positive'
        }
      ],
      options: [],
      boxListRender: [],
      boxList: [
        {
          title: '当前数量',
          field: 'num',
          content: '',
        },
        {
          title: '上月总数',
          field: 'last_num',
          content: '',
        },
        {
          title: '本月新增减少',
          field: 'new_num',
          content: '',
        },
        {
          title: '季度新增减少',
          field: 'quarter_num',
          content: '',
        },
        {
          title: '年度新增减少',
          field: 'year_num',
          content: '',
        },
      ],
    }
  },
  components: {
    CircularBox,
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 0 auto;

  .company-select {
    display: flex;
    margin-bottom: 30px;

    button {
      margin-left: 10px;
      background-color: red;
      color: #fff;
    }
  }

  .base {
    height: 250px;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    box-sizing: border-box;

    .title {
      height: 80px;
      line-height: 2.5;
      margin-left: 10px;

      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #e32322;
      }
    }

    .content {
      border: 2px solid #f8f8f8;
      padding: 10px;
      box-sizing: border-box;
    }

    .box {
      display: flex;
      flex: 1;
    }
  }
}

::v-deep .el-input__inner {
  width: 230px;
}
</style>
